import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import "../scss/Confirmation.scss"

export default function HeroImageFashionTech() {
    const data = useStaticQuery(graphql`
    query MyQuery1020 {
      allContentfulHeroImage(filter: { name: { eq: "Confirmation" } }) {
        edges {
          node {
            heroImage {
              altText
              image {
                file {
                  url
                }
                id
              }
              name
            }
          }
        }
      }
    }
  `)

    return (
        <div className="heroImageConfirmationContainer">
            <img
              className={"heroImageConfirmation"}
              alt={``}
              key={``} 
              src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}

            />
        </div>
    )
}