import * as React from "react"

import Layout from "../../components/Layout"
import HeroImageAbout from "../../components/AboutPage/HeroImageAbout"
import Confirmation from "../../components/Newsletter/Confirmation"
import SentimentPageNewsletter from "../../components/LuxurySentimentPage/SentimentPageNewsletter"


function subscriptionconfirmed({ data }) {
  return (
    <Layout>
      <Confirmation />

    </Layout>
  )
}

export default subscriptionconfirmed
